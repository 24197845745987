<template>
  <div class="max-w-4xl m-auto pt-safe pb-safe">
    <slot></slot>
  </div>
</template>
<script setup>
import {Capacitor} from "@capacitor/core";

const config = useRuntimeConfig('public')
const additionalScripts = []
const collectorUri = config?.public?.issueCollectorFullUri
if (!Capacitor.isNativePlatform() && collectorUri) {
  additionalScripts.push(
      {
        src: collectorUri
      }
  )
}

useHead({
  script: additionalScripts
})
</script>
<style>
/*
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from {
    @apply translate-x-full;
    z-index: 10;
}

.page-enter-to {
    @apply translate-x-0;
    z-index: 10;
}

.page-leave-from {
    @apply translate-x-0;
}

.page-leave-to {
    @apply -translate-x-full;
}*/
</style>
